import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import Rarrow from "../../assets/image/svg/rarrow.svg";
import imgMobile from "../../assets/image/jpeg/hydra.jpg";
import Video from "../../components/vimeo.js";
import img1 from "../../assets/image/jpeg/hydrafacial1.jpg";
import img2 from "../../assets/image/jpeg/hydrafacial2.jpg";
import img3 from "../../assets/image/jpeg/hydrafacial3.jpg";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const FeatureCard = ({ iconImage, title, children, ...rest }) => (
    <Box {...rest}>
      <Box className="d-flex align-items-center " mb={[0, 0, 0, 0]} pb={0}>
        <img src={iconImage} alt="" width="30px" className="mr-3" />
        <Text color="#000 " fontSize="14px" variant="medium">{children}</Text>
      </Box>
      <Box>
       
       
      </Box>
    </Box>
  );


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const ConHydraPage = ({setIsModalOpen}) => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#f7f7fb">
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2">HydraFacial <span>for skin</span></Itext>
</Iwrap>
      <SecondText>
      This is not just a facial, its HydraFacial – a powerful medical grade resurfacing facial that will leave you feeling ready to face life face first.

      </SecondText>
              </Col>
          </Row> 
        <Row className="align-items-center  mb-3">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false"
            >
              <img src={imgMobile} alt="" className="rounded shadow" />
            </div>
          </Col>
          <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>


                We believe that beauty is a feeling, not a physical element. Whether you have dry skin, oily skin or
                combination skin, HydraFacial will improve the look and health of your complexion. <br /> <br />
                This one-of-a-kind treatment delivers instant glowing, refreshed and radiant skin that you can see and
                feel, while improving your skin health over time.<br /> <br />

                HydraFacial regenerates the complexion of your skin, improving conditions such as dry or oily or dull
                skin, acne, uneven skin tone, pigmentation, wrinkles and fine lines. With ongoing treatments, you’ll
                notice vast improvement in hydration, minimised dark spots and remarkably reduced appearance of fine
                lines and wrinkles. <br /> <br />


              </Text>


              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment
                </Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>

      </ Container>


    </Section>
    <SeparatorEnd />
  </>
);

export default ConHydraPage;
