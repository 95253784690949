import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import ACt from '../sections/hydra/hydracontent';
import Con from '../sections/hydra/hydracon.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Acco from "../sections/hydra/hydrafaq.js";
import Pack from "../sections/hydra/hydrapack.js"; 
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/hydrabg.jpg";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import Testimonial from "../sections/index/indexreviews.js";
import PopupForm from "../components/PopupForm/PopupForm"

const HydraTratmentPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return(
  <>
 <Head title="HydraFacial Treatment London Book Online" image="/images/dermamina-home.jpg"  description="Experience the ultimate skin rejuvenation with our Hydrafacial treatment. Deep cleanse, exfoliate, and hydrate for a radiant, glowing complexion. Book today!" keywords="hydrafacial treatment, hydrafacial procedure, hydrafacial benefits, skin rejuvenation, facial treatment, deep cleansing facial, hydradermabrasion, non-invasive facial, skincare treatment, hydrafacial glow"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Hydrafacial Treatment </span> <span style="color: #00aec7;">London</span>'
    secondText="Best skin of your life"
    videoAlt="Hydrafacial"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />
      <ACt
        setIsModalOpen={setIsModalOpen}
      />
      <Con />
   <Pack />
   <Acco
     setIsModalOpen={setIsModalOpen}
   />
   <Testimonial />
      <Clinic />
      <Saveface />
      <Trend />
     </PageWrapper>
    
  </>
)}
export default HydraTratmentPage
